import "./Navigation.css";

import CachedIcon from "@mui/icons-material/Cached";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MasterIndex, SessionHandler } from "verdiapi";

import { IconButtonWithTooltip } from "../../components/generic/IconButtonWithTooltip";
import { LanguageSelector } from "../../components/specialized/LanguageSelector/LanguageSelector";
import { AccountSelect } from "../../components/specialized/MapControlBar/AccountSelect";
import ProvideFeedbackButton from "../../components/specialized/ProvideFeedbackModal/ProvideFeedbackButton";
import FocusContext from "../../services/mapManagement/FocusContext";
import GlobalOptions from "../../utils/GlobalOptions";
import ReactComponentBase from "../../utils/ReactComponentBase";
import { Drawer } from "./Drawer/Drawer";
import { SettingsMenu } from "./SettingsMenu";

function HomeButton() {
    const { t } = useTranslation("modules");

    return (
        <IconButtonWithTooltip
            component={Link}
            to={`/`}
            title={t("navigation.navBar.home")}
            icon={<HomeIcon color={"gray"} />}
        />
    );
}

function RefreshButton() {
    const { t } = useTranslation("modules");
    return (
        <IconButtonWithTooltip
            onClick={async () => {
                window.location.reload();
            }}
            title={t("navigation.navBar.refresh")}
            icon={<CachedIcon />}
        />
    );
}

class NavBar extends ReactComponentBase {
    constructor(props) {
        super(props);

        this.state = {
            menuAnchor: null,
            mobileMenuAnchor: null,
            admin: SessionHandler.admin,
            loggedIn: SessionHandler.loggedIn,
            selectedAccount: "",
            selectedAOI: "",
        };

        SessionHandler.onLogin.addListener(() => {
            this.setState({
                accounts: SessionHandler.accounts,
                admin: SessionHandler.admin,
                loggedIn: SessionHandler.loggedIn,
            });
        });

        SessionHandler.onLogout.addListener(() => {
            this.setState({
                accounts: SessionHandler.accounts,
                admin: SessionHandler.admin,
                loggedIn: SessionHandler.loggedIn,
            });
        });

        sortAccounts();

        SessionHandler.onSessionLoad.addListener(() => {
            sortAccounts();
            setTimeout(() => {
                if (
                    SessionHandler.currentUserObject &&
                    SessionHandler.currentUserObject.id !== this.state.selectedAccount
                ) {
                    this.setState({
                        selectedAccount: SessionHandler.currentUserObject.id,
                    });
                } else {
                    this.forceUpdate();
                }
            }, 100);
        });

        FocusContext.onAOIChange.addListener(() => {
            if (FocusContext.selectedAOI !== this.state.selectedAOI) {
                this.setState({
                    selectedAOI: FocusContext.selectedAOI,
                });
            } else {
                this.forceUpdate();
            }
        });
    }

    componentDidMount() {
        // pass
    }

    render() {
        sortAccounts();
        const multipleAreasOfInterest = MasterIndex.aoi.all.length > 1;
        return (
            <>
                <AppBar position={"static"} style={{ zIndex: 10 }}>
                    <Toolbar style={{ minHeight: "var(--nav-bar-height, 55px)" }} />
                </AppBar>
                <AppBar
                    position={"fixed"}
                    style={{ height: "var(--nav-bar-height, 55px)", zIndex: 1300 }}
                    id={this.uid}
                    elevation={0}
                >
                    <Toolbar style={{ minHeight: "var(--nav-bar-height, 55px)" }}>
                        <MobileView style={{ display: "flex" }}>
                            <IconButton
                                edge={"start"}
                                color={"inherit"}
                                aria-label={"menu"}
                                onClick={(e) => {
                                    this.setState({ mobileMenuAnchor: e.currentTarget });
                                }}
                                size={"large"}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Drawer
                                multipleAOI={multipleAreasOfInterest}
                                open={Boolean(this.state.mobileMenuAnchor)}
                                onClose={() => {
                                    this.setState({ mobileMenuAnchor: null });
                                }}
                                admin={this.state.admin}
                                loggedIn={this.state.loggedIn}
                                mobileMenuAnchorSetter={(anchor) => {
                                    this.setState({ mobileMenuAnchor: anchor });
                                }}
                            />
                        </MobileView>

                        <Link to={"/"}>
                            <img
                                style={{
                                    minHeight: isMobile ? "30px" : "25px",
                                }}
                                src={
                                    "https://verdi-web-assets.s3.us-east-2.amazonaws.com/logo/PNG/white_wordmark@0.5x.png"
                                }
                                id={"main-logo"}
                            />
                        </Link>
                        <div style={{ flexGrow: "10" }} />
                        <MobileView>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <RefreshButton />
                                <HomeButton />
                            </div>
                        </MobileView>

                        <BrowserView className={"MainBrowserToolbar"} style={{ display: "flex" }}>
                            {this.state.loggedIn ? (
                                <>
                                    {this.state.admin && <AccountSelect />}
                                    <RefreshButton />
                                    <HomeButton />
                                    <ProvideFeedbackButton />
                                    <SettingsMenu />
                                    {GlobalOptions.enableLanguageSelector && <LanguageSelector />}
                                    <Button
                                        color={"inherit"}
                                        onClick={() => {
                                            localStorage.setItem("userData", undefined);
                                            window.location.reload();
                                        }}
                                        className={"NavBarButton"}
                                    >
                                        {this.props.t("navigation.navBar.logOut")}
                                    </Button>
                                </>
                            ) : (
                                <Button color={"inherit"} component={Link} to={"/about"}>
                                    About
                                </Button>
                            )}
                        </BrowserView>

                        {!this.state.loggedIn && (
                            <Button color={"inherit"} component={Link} to={"/auth"}>
                                {this.props.t("navigation.navBar.logIn")}
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
            </>
        );
    }
}

export default withTranslation(["modules"])(NavBar);

function sortAccounts() {
    if (!SessionHandler.accounts) {
        return;
    }

    SessionHandler.accounts.sort((a, b) => {
        if (a.name && b.name) {
            return a.name.localeCompare(b.name);
        }
        if (a.name) {
            return 1;
        }
        return -1;
    });
}
