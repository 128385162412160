export type Rows = {
    [key: string]: any;
}[];

export type Columns = string[];

export enum ColumnGrouping {
    SOURCE_TYPE = "sourceType",
    ZONE_NAME = "zoneName",
    DATA_TYPE = "dataType",
}

export interface PerDataKeyValues {
    sourceIDsPerDataKey: Record<string, string>;
    sourceTypesPerDataKey: Record<string, string>;
    sourceNamesPerDataKey: Record<string, string>;
    dataTypesPerDataKeys: Record<string, string>;
    zoneNamesPerDataKey: Record<string, string>;
}
