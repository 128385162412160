import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Accordion } from "src/components/generic/Accordion";
import { ComboBoxOption } from "src/components/generic/ComboBox/ComboBox";
import FocusContext from "src/services/mapManagement/FocusContext";
import { dayMs } from "verdiapi/dist/HelperFunctions";
import { DataTypeSpecifier } from "verditypes";

import BetaTag from "../../components/generic/BetaTag/BetaTag";
import ColumnGroupSelector from "./components/ColumnGroupSelector";
import { DataTypeSelector } from "./components/DataTypeSelector";
import { DateRangeSelector } from "./components/DateRangeSelector/DateRangeSelector";
import { SourceSelector } from "./components/SourceSelector";
import { SOURCE_TYPE } from "./constants";
import { createCSVData } from "./helpers/csv";
import { createFileName, exportToCsv } from "./helpers/file";
import { ColumnGrouping } from "./types";

const columnGroupingKeyOptions: { value: ColumnGrouping; label: string }[] = [
    { value: ColumnGrouping.SOURCE_TYPE, label: "Data Source category (Default)" },
    { value: ColumnGrouping.DATA_TYPE, label: "Data Type" },
    { value: ColumnGrouping.ZONE_NAME, label: "Zone name" },
];
export function DataExport() {
    const now = new Date(Date.now());
    const eodToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    const [startDate, setStartDate] = useState<Date>(new Date(eodToday.valueOf() - dayMs * 7 + 1));
    const [endDate, setEndDate] = useState<Date>(eodToday);

    const [columnGroupingKey, setColumnGroupingKey] = useState<ColumnGrouping>(columnGroupingKeyOptions[0].value);

    const [dataTypes, setDataTypes] = useState<ComboBoxOption[]>([]);
    const [sources, setSources] = useState<ComboBoxOption[]>([]);

    const onClickExport = async () => {
        // Update the loading overlay
        FocusContext.updateLoadHandler("Exporting data...", "DataExport");
        console.info("DataExport: Exporting data...");

        const sourceType = sources[0].group as SOURCE_TYPE;
        const sourceID = sources[0].value;

        // TODO fix createCSVData to support multiples source types
        const { rows, columns } = await createCSVData({
            sourceType,
            sourceID,
            dataTypes: dataTypes.map((option) => option.value) as DataTypeSpecifier[],
            startDate,
            endDate,
            columnGroupKey: columnGroupingKey,
        });

        // Update the loading overlay
        FocusContext.updateLoadHandler("Downloading data...", "DataExport");
        console.info("DataExport: Downloading data...");

        // Download & export the data
        const filename = createFileName(sourceType, sourceID, startDate, endDate);
        exportToCsv(filename, rows, columns);

        // End the loading overlay
        FocusContext.resolveLoadHandler("DataExport");
    };

    const onChangecolumnGroupingKey = (value: string) => {
        setColumnGroupingKey(value as ColumnGrouping);
    };

    return (
        <Stack
            direction={"column"}
            alignItems={"center"}
            sx={{ p: 12, mx: "auto", overflow: "auto", maxHeight: "calc(100% - var(--nav-bar-height))" }}
        >
            <Stack direction={"column"} spacing={8} sx={{ maxWidth: 800 }}>
                <Stack direction={"column"} sx={{ mb: 8 }}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography variant={"h2"} sx={{ fontWeight: 600 }}>
                            Data Export
                        </Typography>
                        <BetaTag />
                    </Stack>
                </Stack>
                <DateRangeSelector
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
                <SourceSelector sources={sources} setSources={setSources} />
                <DataTypeSelector dataTypes={dataTypes} setDataTypes={setDataTypes} />
                <Accordion
                    defaultExpanded={true}
                    title={"Advanced Settings"}
                    accordionSummarySx={{
                        borderBottom: "1px solid #E0E0E0",
                    }}
                >
                    <ColumnGroupSelector options={columnGroupingKeyOptions} onChange={onChangecolumnGroupingKey} />
                </Accordion>

                <Button sx={{ width: 150, mt: 6 }} variant={"contained"} onClick={onClickExport}>
                    Export to CSV
                </Button>
            </Stack>
        </Stack>
    );
}
