import { NDVIColorMap } from "./NDVIColorMap";

export function getNDVIColor(NDVIValue) {
    if (NDVIValue) {
        const mapIndex = NDVIColorMap.length - Math.round(NDVIValue * NDVIColorMap.length);
        const color = NDVIColorMap[mapIndex];
        const r = color[0] * 255;
        const g = color[1] * 255;
        const b = color[2] * 255;
        return `rgba(${r}, ${g}, ${b})`;
    }
    return `rgba(189, 189, 189)`;
}

export function getNDVIColorComponents(NDVIValue) {
    if (NDVIValue) {
        const mapIndex = NDVIColorMap.length - Math.round(NDVIValue * NDVIColorMap.length);
        const color = NDVIColorMap[mapIndex];
        const r = color[0] * 255;
        const g = color[1] * 255;
        const b = color[2] * 255;
        return [r, g, b];
    }
    return [189, 189, 189];
}
