import { MasterIndex } from "verdiapi";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";
import { DataTypeSpecifier } from "verditypes";
import { dayMs, minuteMs } from "verditypes/dist/timeConstants";

import { SOURCE_TYPE, USER_DATA_TYPES } from "../constants";
/**
 *
 * @param {String} sourceType
 * @param {String} sourceID
 * @param {Array} dataTypes
 *
 * @returns {HistoricalDataBase}
 */
interface GenerateDatabaseParams {
    sourceType: SOURCE_TYPE;
    sourceID: string;
    dataTypes?: DataTypeSpecifier[];
}

export function createDatabase({ sourceType, sourceID, dataTypes }: GenerateDatabaseParams) {
    // Define arrays to store the sources and relevantZones to get data for
    let sourceIDs: string[] | undefined;
    let relevantZoneIDs: string[] | undefined;

    // Add sources and relevantZones based on the source type
    switch (sourceType) {
        case "account":
            // Do nothing, all sources and relevantZones will be included
            break;
        case "field":
            // Set relevant zones to the AOI's zones
            relevantZoneIDs = MasterIndex.aoi.byID[sourceID].zonesInArea.map((z) => z.id);
            break;
        case "zone":
            // Set relevant zones to the zone
            relevantZoneIDs = [sourceID];
            break;
        case "device":
            // Set sources to the device
            sourceIDs = [sourceID];
            break;
        default:
            break;
    }

    const dbDataTypes = dataTypes || (USER_DATA_TYPES as unknown as DataTypeSpecifier[]);

    // Create the database with the specified sources, data types, and relevant zones
    // @ts-ignore
    const database = new HistoricalDataBase(sourceIDs as any, dbDataTypes, minuteMs * 3, {
        relevantZoneIDs: relevantZoneIDs,
        worryAboutPosition: false,
        worryAboutRelevantZones: true,
    });

    // Return the database
    return database;
}

/**
 * Loads data from the db for the given time range
 */
export async function loadData(db: HistoricalDataBase, startDate: Date, endDate: Date) {
    // Start database cursor at start time
    let cursor = startDate;

    // Download data in 60 day chunks
    while (cursor.valueOf() < endDate.valueOf()) {
        // Move cursor forward 60 days or to the end time, whichever is smaller
        cursor = new Date(
            Math.min(
                cursor.valueOf() + dayMs * 60, // 60 days
                endDate.valueOf(),
            ),
        );

        console.info(`DataExport: Getting data from ${startDate.toString()} to ${cursor.toString()}`);

        // Get the data from the database (will ignore already downloaded data)
        // eslint-disable-next-line no-await-in-loop
        await db.getData(startDate, cursor);
    }

    console.info(`DataExport: Data has been loaded`);
}
