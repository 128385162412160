/* eslint-disable import/no-webpack-loader-syntax,import/extensions,import/no-unresolved */
import React from "react";

import source from "!!raw-loader!./BetaTagDemo.jsx";

import BetaTag from "./BetaTag";
import readme from "./README.md";

export function BetaTagDemo() {
    return (
        <div>
            <BetaTag />
        </div>
    );
}

export default {
    component: BetaTagDemo,
    name: "BetaTag",
    readme: readme,
    source: source,
};
