import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import Papa from "papaparse";
import { MasterIndex, SessionHandler } from "verdiapi";
import { secondMs } from "verditypes/dist/timeConstants";

import { FILENAME_DATE_FORMAT, FILENAME_DATETIME_FORMAT, SOURCE_TYPE } from "../constants";
/**
 * Generates a filename for the exported data
 */
export function createFileName(sourceType: SOURCE_TYPE, sourceID: string, startDate: Date, endDate: Date) {
    // Get the source name
    let sourceName;
    switch (sourceType) {
        case "account":
            sourceName = SessionHandler.currentUserObject.username;
            break;
        case "field":
            sourceName = MasterIndex.aoi.byID[sourceID].name;
            break;
        case "zone":
            sourceName = MasterIndex.zone.byID[sourceID].name;
            break;
        case "device":
            if (MasterIndex.irrigationDevice.byID[sourceID]) {
                sourceName = MasterIndex.irrigationDevice.byID[sourceID].name;
            } else if (MasterIndex.blockValve.byID[sourceID]) {
                sourceName = MasterIndex.blockValve.byID[sourceID].name;
            } else if (MasterIndex.thirdPartyDevice.byID[sourceID]) {
                sourceName = MasterIndex.thirdPartyDevice.byID[sourceID].name;
            } else {
                sourceName = "unknown device name";
            }
            break;
        default:
            sourceName = "unknown source";
            break;
    }

    const startDateFormatted = getTimeString(startDate, FILENAME_DATE_FORMAT);
    const endDateFormatted = getTimeString(endDate, FILENAME_DATE_FORMAT);
    const currentDateTimeFormatted = getTimeString(new Date(), FILENAME_DATETIME_FORMAT);
    return `${sourceType}_'${sourceName}'_${startDateFormatted}_to_${endDateFormatted}_downloaded_at_${currentDateTimeFormatted}.csv`;
}

/**
 *
 * @param {string} filename
 * @param {Array} rows
 * @param {Array} columnOrder
 *
 * @returns
 */

export function exportToCsv(filename: string, rows: any, columnOrder: any) {
    // Start the export timer
    const exportStartTime = Date.now();

    // Convert the data to CSV
    console.info(`DataExport: Exporting data to CSV... `);
    const csv = Papa.unparse(rows, {
        columns: columnOrder,
    });
    console.info(
        `DataExport: Exported data to CSV with ${rows.length} rows in ${(Date.now() - exportStartTime) / secondMs} seconds`,
    );

    // Create a blob from the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Download the blob
    // Check for IE 10+ support for msSaveBlob
    if ((navigator as any).msSaveBlob) {
        (navigator as any).msSaveBlob(blob, filename);
    } else {
        // Create a link element
        const link = document.createElement("a");

        // Check if the link element supports the download attribute
        if (link.download !== undefined) {
            // Start the object URL timer
            const createObjectStartTime = Date.now();
            console.info(`DataExport: Creating object URL`);

            // Create an object URL from the blob
            const url = URL.createObjectURL(blob);
            console.info(
                `DataExport: Created object URL in ${(Date.now() - createObjectStartTime) / secondMs} seconds`,
            );

            // Set the link element's attributes
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";

            // Add, click, and then remove the link element from the document body
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
