/**
 *  Decouple from mobile inplementation for now. We are updating the desktop view to use icons
 *  but leaving the mobile alone
 * */
export const DesktopAdminSettingsNavList = [
    {
        url: "/settings",
        name: "Settings",
    },
    {
        url: "/DeviceDebugger",
        name: "Device Debugger",
    },
    {
        url: "/UserManager",
        name: "User Manager",
    },
    {
        url: "/DataExport",
        name: "Data Export",
    },
    {
        url: "/AdminUserManager",
        name: "Admin User Manager",
    },
];

export const AdminToolsNavList = [
    {
        url: "/DeviceDebugger",
        name: "Device Debugger",
    },
    {
        url: "/UserManager",
        name: "User Manager",
    },
    {
        url: "/DataExport",
        name: "Data Export",
    },
    {
        url: "/AdminUserManager",
        name: "Admin User Manager",
    },
];
