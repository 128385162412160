import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import { withSnackbar } from "notistack";
import React from "react";

import InfoCard from "./InfoCard";

class AreaOfInterestInfoCardTemp extends InfoCard {
    constructor(props) {
        super(props);
        this.DeviceName = this.Target.name;
        this.title = (
            <TextField
                style={{
                    width: "100%",
                }}
                defaultValue={this.Target.name}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={(e) => {
                    const a = { ...e };
                    this.Target.name = a.target.value;
                    this.triggerOnChange(a.target.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={"end"}>
                            <EditIcon />
                        </InputAdornment>
                    ),
                }}
            />
        );
        this.ContentComponent = AreaOfInterestInfoCardBody;
        this.duckable = true;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    k = 0;

    triggerOnChange(newName) {
        this.k++;
        const temp = this.k;
        setTimeout(() => {
            if (temp === this.k) {
                try {
                    this.Target.edit({
                        name: newName,
                    })
                        .then(() => {
                            this.props.enqueueSnackbar("Saved zone name");
                        })
                        .catch((e) => {
                            console.warn(e);
                            this.props.enqueueSnackbar("Could not save zone name", { variant: "error" });
                        });
                } catch (e) {
                    console.warn(e);
                    this.props.enqueueSnackbar("Could not save zone name", { variant: "error" });
                }
            }
        }, 3000);
    }
}
export const AreaOfInterestInfoCard = withSnackbar(AreaOfInterestInfoCardTemp);

export function AreaOfInterestInfoCardBody({ Target }) {
    return (
        <List className={"InfoPane_detailList"} key={`ActuallyGenned_InfoCard--${Target.UID}`}>
            <ListItem>
                <Typography variant={"subtitle1"} style={{ color: "#757575", marginBottom: "4vh" }}>
                    {`ID: ${Target.id}`}
                </Typography>
            </ListItem>
        </List>
    );
}
