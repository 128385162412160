import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";

/**
 * IconButtonWithTooltip is a wrapper around the IconButton component that adds a tooltip to the IconButton.
 */
export function IconButtonWithTooltip({ title, icon, ...props }) {
    return (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Tooltip arrow placement={"top"} title={title}>
                <IconButton {...props}>{icon}</IconButton>
            </Tooltip>
        </Box>
    );
}
