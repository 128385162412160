/* eslint-disable react/no-unused-class-component-methods */
import "./InfoCardBase.scss";

import { Close as CloseIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";
import { CardHeader } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { BufferedAction } from "gardenspadejs/dist/general";
import React, { Component } from "react";
import SimpleBar from "simplebar-react";
import { EventHandler } from "verdiapi";

import FocusContext from "../../../services/mapManagement/FocusContext";
import GhostFocusContext from "../../../services/mapManagement/mapEntities/GhostFocusContext";
import GlobalOptions from "../../../utils/GlobalOptions";
import { GenerateUID } from "../../../utils/HelperFunctions";

export const AllPanels = [];
export const NewInfoCardCreated = new EventHandler();
export default class InfoCard extends Component {
    static DefaultZIndex = 800;

    /**
     *
     * @type {React.ReactNode | React.ReactNode[]}
     */
    ContentComponent = null;

    _BodyElement;

    _CardElement;

    _HeaderElement;

    _HeaderHeightSet = false;

    _HeaderSize = 0;

    _OwnElement;

    _VisibilityState = "hidden";

    /**
     * @type {typeof INFO_CARD_TYPES[keyof typeof INFO_CARD_TYPES]} see INFO_CARD_TYPES
     */
    currentInfoCardType;

    /**
     * @type{React.ReactNode}
     */
    avatar;

    bounceLoad = false;

    /**
     *
     * @type {any[]}
     */
    classNames = [];

    errorInfo = "";

    /**
     * @type{FocusContext}
     */
    focusContext;

    /**
     * @type{React.ReactNode}
     */
    header;

    key = 0;

    /**
     *
     * @param windowHeight
     * @return {string}
     */
    minHeightExpression = undefined;

    scrollable = true;

    /**
     * @type{React.ReactNode | string}
     */
    subheader;

    /**
     * @type{React.ReactNode | string}
     */
    title;

    /**
     *
     * @param {{Parent: MapEntity}}props
     */
    constructor(props) {
        super(props);
        this.state = {
            LoadContent: false,
            Visibility: "hidden",
            error: false,
        };
        this.focusContext = new GhostFocusContext();
        this.header = this.props.header;
        this.Target = this.props.Target;
        this.MapEntity = this.props.MapEntity;
        this.subheader = this.props.subheader;
        this.avatar = this.props.avatar;
        this.duckable = this.props.duckable;
        this.title = this.props.title;
        this.uid = GenerateUID("InformationPanelUID");

        AllPanels.push(this);

        if (this.props.MapEntity) {
            this.props.MapEntity.infoCard = this;
        }
        this.bufferedContentLoadAction = new BufferedAction(
            () => {
                this.setState({
                    LoadContent: this._ContentShouldLoad,
                    Visibility: this._VisibilityState,
                });
            },
            250,
            false,
            true,
        );
        this.simpleBarRef = React.createRef();
    }

    componentDidMount() {
        this.setMaxHeight();

        if (this.simpleBarRef && this.simpleBarRef?.current?.removeObserver) {
            this.simpleBarRef.current.removeObserver();
        }
    }

    componentDidCatch(error, info) {
        this.errorInfo = JSON.stringify(info);
        this.forceUpdate();
    }

    componentWillUnmount() {
        EventHandler.disposeOfAllHooksForUID(this.uid);
    }

    setHeaderHeight() {
        requestAnimationFrame(() => {
            if (this.HeaderElement) {
                const HeaderSize = Math.max(40, this.HeaderSize);
                this.RootElement.style.setProperty("--header-height", `${HeaderSize}px`);
                this._HeaderHeightSet = true;
            }
        });
    }

    setMaxHeight() {
        window.requestAnimationFrame(() => {
            try {
                this.RootElement.style.setProperty("--maxInfoPaneHeight", this.maxHeightExpression(window.innerHeight));

                if (this.minHeightExpression) {
                    this.RootElement.style.setProperty(
                        "--minInfoPaneHeight",
                        this.minHeightExpression(window.innerHeight),
                    );
                }
            } catch (e) {
                console.warn(e);
            }
        });
    }

    get BodyElement() {
        if (!this._BodyElement) {
            this._BodyElement = document.querySelector(`#${this.uid} .InfoPaneBody`);
        }
        return this._BodyElement;
    }

    get CardElement() {
        if (!this._CardElement) {
            this._CardElement = document.querySelector(`#${this.uid} .MuiCard-root`);
        }
        return this._CardElement;
    }

    /**
     *
     * @return {HTMLElement}
     */
    get HeaderElement() {
        if (!this._HeaderElement) {
            this._HeaderElement = document.querySelector(`#${this.uid} .InfoCardMainCard > div.InfoCardHeader`);
            if (!this._HeaderElement) {
                this._HeaderElement = document.querySelector(`#${this.uid} .InfoCardMainCard > div[class*="Header"]`);
            }
            if (!this._HeaderElement) {
                this._HeaderElement = document.querySelector(`#${this.uid} .InfoCardMainCard > div`);
            }
        }
        return this._HeaderElement;
    }

    get HeaderSize() {
        if (this.HeaderElement) {
            if (this._HeaderSize <= 0) {
                this._HeaderSize = this.HeaderElement.getBoundingClientRect().height;
            }
        }
        return this._HeaderSize;
    }

    get RootElement() {
        if (!this._OwnElement) {
            this._OwnElement = document.querySelector(`#${this.uid}`);
        }
        return this._OwnElement;
    }

    /**
     * Sets the visiblity of the component. Can be hidden, ducked, or visible
     * @param {"hidden" | "ducked" | "visible"} v
     */
    set VisibilityState(v) {
        if (this.RootElement) {
            setTimeout(() => {
                this.setHeaderHeight();
            }, 500);
            window.requestAnimationFrame(() => {
                const newVisiblityClass = `InfoPane_CardHolder--${v}`;
                this.RootElement.classList.add(newVisiblityClass);
                this.RootElement.classList.remove(
                    ...[
                        "InfoPane_CardHolder--hidden",
                        "InfoPane_CardHolder--ducked",
                        "InfoPane_CardHolder--visible",
                    ].filter((a) => a !== newVisiblityClass),
                );
                if (v === "hidden") {
                    this.RootElement.style.zIndex = (InfoCard.DefaultZIndex + 20).toString();
                } else {
                    this.RootElement.style.zIndex = InfoCard.DefaultZIndex.toString();
                }
            });
        }

        if (v === "hidden") {
            this.key++;
            const temp = this.key;

            // Only unload the content if the info card isn't immediately pulled back up
            setTimeout(() => {
                if (temp === this.key) {
                    window.requestAnimationFrame(() => {
                        this.SetLoadedContentState(false);
                    });
                }
            }, 700);
            this.CauseInteractEvent();
        } else if (v === "ducked" || v === "visible") {
            this.key++;
            this.CauseInteractEvent();
            this.SetLoadedContentState(true);
        } else {
            console.warn(`Visibility state must be hidden, ducked, or visible. ${v} is not a valid visibility state`);
            console.warn(v);
            return;
        }

        if (this._VisibilityState === "hidden" && v !== "hidden") {
            if (this.focusContext) {
                FocusContext.pushContextToStack(this.focusContext);
            }
        }
        if (v === "hidden" && this._VisibilityState !== "hidden") {
            if (this.focusContext && FocusContext.contextStack.includes(this.focusContext)) {
                FocusContext.releaseStack(this.focusContext);
            }
        }
        this._VisibilityState = v;
        this.state.Visibility = this._VisibilityState;
        // setTimeout(() => {
        //     if (keyToDetermineIfAlreadyRefreshing === this._LoadContentFingerprint) {
        //         this.setState({ Visibility: this._VisibilityState });
        //     }
        // }, 250);
        this._VisibilityState = v;
    }

    get VisibilityState() {
        return this._VisibilityState;
    }

    /**
     *
     * @param windowHeight
     * @return {string}
     */
    maxHeightExpression = (windowHeight) => `${((windowHeight - 200) * 0.8).toString()}px`;

    focus() {
        if (this.duckable) {
            this.VisibilityState = "ducked";
        } else {
            this.VisibilityState = "visible";
        }
    }

    headerClickHandler(e) {
        if (this.VisibilityState === "ducked") {
            this.VisibilityState = "visible";
        } else if (this.duckable && this.VisibilityState === "visible") {
            this.VisibilityState = "ducked";
        }
        e.stopPropagation();
    }

    /**
     * Fires when the close button on the info card is clicked
     * @param e
     */
    closeHandler(e) {
        this.VisibilityState = "hidden";
        const actuallyClose = () => {
            setTimeout(() => {
                if (FocusContext.currentInfoCard === this) {
                    FocusContext.currentInfoCard = undefined;
                }
            }, 200);
        };
        if (this.focusContext) {
            FocusContext.releaseStack(this.focusContext).then(() => {
                actuallyClose();
            });
        } else {
            actuallyClose();
        }

        if (e) {
            e.stopPropagation();
        }
    }

    SetLoadedContentState(v) {
        this._ContentShouldLoad = v;
        this.bufferedContentLoadAction.trigger();
    }

    CauseInteractEvent() {
        if (!this._interacting) {
            window.requestAnimationFrame(() => {
                this.RootElement.classList.add("InfoPane_CardHolder--interacted");
                this._interacting = true;
            });
            this.RootElement.addEventListener("animationend", () => {
                window.requestAnimationFrame(() => {
                    this.RootElement.classList.remove("InfoPane_CardHolder--interacted");
                    this._interacting = false;
                });
            });
        }
    }

    usurp() {
        this.VisibilityState = "hidden";
    }

    render() {
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                if (this.simpleBarRef?.current?.recalculate) {
                    this.simpleBarRef.current.recalculate();
                }
            });
        }, 100);
        this.Target = this.Target || {
            uid: this.uid,
        };
        let TargetUID = this.uid;
        if (this.Target) {
            TargetUID = this.Target.uid;
        }
        // Avatar processing
        let avatar = this.avatar;
        if (!avatar) {
            if (this.props.Target && this.props.Target.status) {
                let avatarClassName = `InfoCardAvatar ME MEo ME_${this.props.Target.status} `;
                if (this.props.Target.MapEntity) {
                    avatarClassName += (this.props.Target.MapEntity.DOMClassNames || []).join(" ");
                }
                avatar = (
                    <Avatar aria-label={"Sensor"} className={avatarClassName}>
                        <this.props.MapEntity.icon style={{ transform: "scale(1.2)" }} />
                    </Avatar>
                );
            } else {
                avatar = "";
            }
            this.avatar = avatar;
        }
        let title = this.title;

        // if(title && ( title instanceof  String || typeof(title) === "string")){
        //     title = <Typography variant={"h5"} component={"p"}>title</Typography>;
        // }
        if (title instanceof Function) {
            title = this.title();
        }

        // Header Processing
        const action = this.props.action || this.action || null;
        let header = this.props.header;
        if (!header || this.title instanceof Function) {
            header = this.header;
            if (!header || this.title instanceof Function) {
                header = (
                    <CardHeader
                        className={"InfoCardHeader"}
                        onClick={() => {}}
                        title={title}
                        action={
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                {action}
                                {this.duckable && (
                                    <IconButton
                                        aria-label={"open"}
                                        onClick={(e) => {
                                            this.headerClickHandler(e);
                                        }}
                                        size={"large"}
                                    >
                                        <ExpandLessIcon className={"InfoPanel_HideButton"} />
                                    </IconButton>
                                )}
                                {!this.duckable && (
                                    <IconButton
                                        aria-label={"close"}
                                        onClick={(e) => {
                                            this.closeHandler(e);
                                        }}
                                        size={"large"}
                                    >
                                        <CloseIcon className={"InfoPanel_CloseButton"} />
                                    </IconButton>
                                )}
                            </div>
                        }
                        subheader={this.subheader || ""}
                        avatar={avatar}
                    />
                );
                this.header = header;
            }
        }

        // Class Name processing
        const newVisiblityClass = ` InfoPane_CardHolder--${this.VisibilityState}`;
        const allClassNames = [...(this.classNames || [])];
        if (this.props.className) {
            allClassNames.push(this.props.className);
        }

        allClassNames.push(newVisiblityClass);
        if (this.bounceLoad) {
            allClassNames.push("InfoPane_CardHolder--bounceLoad");
        }
        allClassNames.push("InfoPane_CardHolder");
        const rootClassName = allClassNames.join(" ");

        return (
            <div id={this.uid} className={`${rootClassName} InfoCardWrapper`}>
                <div id={`Tray--${TargetUID}`} key={`Generated_tray--${TargetUID}`} className={"InfoPanelTray"}>
                    {this.TrayComponent && (
                        <this.TrayComponent
                            id={`InfoCardTray--${TargetUID}`}
                            Target={this.Target}
                            Visiblity={this.state.visibility}
                            getVisibility={() => this.VisibilityState}
                        />
                    )}
                </div>
                <Card className={`InfoCardMainCard InfoCardMainCard--${this.VisibilityState}`}>
                    {header}
                    {!this.state.error && (
                        <CardContent
                            component={this.scrollable && GlobalOptions.useSimpleBar ? SimpleBar : null}
                            ref={this.simpleBarRef}
                            id={`InfoCard--${this.Target.uid}`}
                            key={`Generated_InfoCard--${this.Target.uid}`}
                            className={`InfoPaneBody InfoPaneBody--${
                                this.ContentComponent && this.state.LoadContent ? "loaded" : "unloaded"
                            }${this.scrollable && !GlobalOptions.useSimpleBar ? " InfoPaneBody--nativeScroll" : ""}`}
                            style={{ padding: "0px" }}
                        >
                            {
                                this.ContentComponent && this.state.LoadContent && (
                                    // <Suspense fallback={<div style={{minHeight:"50vh"}}>Loading</div>}>
                                    <this.ContentComponent
                                        Target={this.Target}
                                        Visiblity={this.state.visibility}
                                        InfoCard={this}
                                        MapEntity={this.MapEntity}
                                    />
                                )
                                // </Suspense>
                            }
                        </CardContent>
                    )}
                    {this.state.error && (
                        <CardContent
                            id={`InfoCard--${this.Target.uid}`}
                            key={`Generated_InfoCard--${this.Target.uid}`}
                            className={`InfoPaneBody InfoPaneBody--${
                                this.ContentComponent && this.state.LoadContent ? "loaded" : "unloaded"
                            }`}
                        >
                            <div> There has been an error loading this componenet. Please report this to our team </div>
                            <div> {this.errorInfo} </div>
                        </CardContent>
                    )}
                </Card>
            </div>
        );
    }
}
