import "./ProvideFeedbackModal.scss";

import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GenerateUID } from "gardenspadejs/dist/general";
import React from "react";
import { SessionHandler } from "verdiapi";

import SimpleBarCompatability from "../../generic/SimpleBarCompatability/SimpleBarCompatability";

export default class ProvideFeedbackModal extends React.Component {
    constructor(props) {
        super(props);
        this.uid = GenerateUID("ProvideFeedbackModal");
        this.state = {
            feedbackString: "",
            sendStatus: "composing",
        };
    }

    render() {
        const className = `ProvideFeedbackModal ProvideFeedbackModal--${this.state.sendStatus}`;

        return (
            <Dialog
                onClose={this.props.handleClose}
                open={this.props.open}
                className={className}
                id={this.uid}
                maxWidth={"md"}
                fullWidth
            >
                <DialogTitle>Send us feedback!</DialogTitle>
                <DialogContent>
                    <div className={`SuccessOverlay`}>
                        <Typography variant={"h3"} className={"ThanksText"}>
                            Thanks!
                        </Typography>
                        <Typography variant={"subtitle1"}>We appreciate your feedback!</Typography>
                        <Button
                            className={"FinishedFeedbackButton"}
                            variant={"contained"}
                            onClick={() => {
                                this.props.handleClose({});
                                this.setState({
                                    sendStatus: "composing",
                                    feedbackString: "",
                                });
                            }}
                        >
                            Close
                        </Button>
                    </div>
                    <SimpleBarCompatability>
                        <Typography variant={"subtitle1"}>
                            Whether you're having a problem, have a feature you want to see, or just want to tell us
                            what you think, we read every piece of feedback we receive and we use it to inform our
                            decisions.
                        </Typography>

                        <TextField
                            className={"FeedbackTextInput"}
                            label={"Feedback"}
                            multiline
                            minRows={6}
                            value={this.state.feedbackString}
                            onChange={(e) => {
                                this.setState({ feedbackString: e.target.value });
                            }}
                        />

                        <Typography variant={"subtitle1"}>
                            Note: If you require immediate assistance, please phone your contact at Verdi directly.
                        </Typography>
                    </SimpleBarCompatability>
                    <Button
                        className={"SubmitButton"}
                        variant={"contained"}
                        onClick={() => {
                            this.setState({
                                sendStatus: "sending",
                            });
                            SessionHandler.doFetch("users/feedback", {
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": "application/json",
                                },
                                method: "POST",
                                redirect: "follow",
                                body: JSON.stringify({ feedback: this.state.feedbackString }),
                            })
                                .then(() => {
                                    this.setState({
                                        sendStatus: "success",
                                    });
                                })
                                .catch(() => {
                                    this.setState({
                                        sendStatus: "error",
                                    });
                                });
                        }}
                    >
                        {this.state.sendStatus === "error" ? "Failed, try again?" : "Submit"}
                    </Button>
                </DialogContent>
            </Dialog>
        );
    }
}
