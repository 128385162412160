import "./ProvideFeedbackModal.scss";

import RateReviewIcon from "@mui/icons-material/RateReview";
import { MenuItem } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";

import { IconButtonWithTooltip } from "../../generic/IconButtonWithTooltip";
import ProvideFeedbackModal from "./ProvideFeedbackModal";

/**
 * this variable holds a reference to the function that actually opens the tooltip window
 * @type {undefined}
 * @private
 */
let _openFeedbackWindow;
/**
 * this exported function will open the tooltip window on demand, allowing other components elsewhere on the page to open
 * the feedback modal
 * @type {undefined}
 * @private
 */
export const OpenFeedbackWindow = () => {
    if (_openFeedbackWindow) {
        _openFeedbackWindow();
    }
};
class ProvideFeedbackButton extends React.Component {
    /**
     * keeps track of whether this component is currently mounted
     * @type {boolean}
     */
    mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        // all of this .mounted logic is just to ensure that you don't run into an error when opening
        // the feedback window that is caused when you setState on an unmounted component.
        _openFeedbackWindow = () => {
            if (this.mounted) {
                this.setState({ open: true });
            }
        };
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <>
                {!isMobile && (
                    <IconButtonWithTooltip
                        title={this.props.t("provideFeedbackModal.feedbackBtn.feedbackBtnTitle")}
                        icon={<RateReviewIcon />}
                        onClick={() => {
                            this.setState({ open: true });
                        }}
                    />
                )}
                {isMobile && (
                    <MenuItem
                        onClick={() => {
                            this.setState({ open: true });
                        }}
                    >
                        {this.props.t("provideFeedbackModal.feedbackBtn.feedbackBtnTitle")}
                    </MenuItem>
                )}

                <ProvideFeedbackModal
                    open={this.state.open}
                    handleClose={() => {
                        this.setState({ open: false });
                    }}
                />
            </>
        );
    }
}
export default withTranslation(["specializedComponents"])(ProvideFeedbackButton);
