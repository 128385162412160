import "./DateAvatar.scss";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { getTimeString } from "gardenspadejs/dist/dateHelpers";
import React from "react";
import { ShortMonthLookup } from "verdiapi/dist/HelperFunctions";

export function DateAvatar({ date, includeTime, includeYear, className, ...propsToPass }) {
    const shortMonth = ShortMonthLookup[date.getMonth()];

    let avatarClassName = `AgendaDateAvatar Override ${className || ""}`;

    if (includeTime || includeYear) {
        avatarClassName += " AgendaDateAvatar--timeIncluded ";
    }
    return (
        <Avatar variant={"rounded"} className={avatarClassName} {...propsToPass}>
            <Typography className={"ClickForAgendaPopup"} component={"p"} variant={"button"}>
                {shortMonth}
            </Typography>
            <Typography className={"ClickForAgendaPopup"} component={"p"} variant={"button"}>
                {date.getDate()}
            </Typography>
            {includeTime && (
                <Typography className={"AgendaAvatarTime"} component={"p"} variant={"button"}>
                    {getTimeString(new Date(date.valueOf()), "h:mm nn")}
                </Typography>
            )}
            {includeYear && (
                <Typography className={"AgendaAvatarTime"} component={"p"} variant={"button"}>
                    {`20${getTimeString(new Date(date.valueOf()), "yy")}`}
                </Typography>
            )}
        </Avatar>
    );
}
