import { Button, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { sleep } from "gardenspadejs/dist/general";
import React from "react";
import { SessionHandler } from "verdiapi";

import FocusContext from "../../../../services/mapManagement/FocusContext";
import { DynamicGraphContext, DynamicGraphContextProvider } from "../../DynamicGraph/DynamicGraphUtility";
import FullsizeZoneOverview from "../../FullSizeZoneOverview/fullsizeZoneOverview";
import ZoneProfileEditor from "../../ZoneProfileEditor/ZoneProfileEditor";

export const ZoneInfoCardBody = React.memo((props) => {
    const graphContextToProvide = React.useMemo(() => new DynamicGraphContextProvider(), []);
    const [editorOpen, setEditorOpen] = React.useState(false);
    React.useEffect(() => {
        // immediately call the async function. This dodges the fact that use effect cannot return a promise.
        (async () => {
            let contextPushed = false;
            let i = 0;
            while (!contextPushed && i < 20) {
                i++;
                if (
                    !FocusContext.contextStack.includes(graphContextToProvide.linkedFocusContext) &&
                    FocusContext.contextStack.includes(props.InfoCard.focusContext)
                ) {
                    FocusContext.pushContextToStack(graphContextToProvide.linkedFocusContext);
                    contextPushed = true;
                }
                await sleep(200);
            }
        })();

        return () => {
            FocusContext.releaseStack(graphContextToProvide.linkedFocusContext);
        };
    }, []);
    return (
        <DynamicGraphContext.Provider value={graphContextToProvide}>
            <List className={"InfoPane_detailList"} key={`ActuallyGenned_InfoCard--${props.Target.UID}`}>
                {/* <StyledEngineProvider injectFirst> */}
                {/*    <ThemeProvider theme={mtheme}> */}
                <ListItem className={"FullsizeZoneOverviewListItem--InfoCard"}>
                    <FullsizeZoneOverview
                        focusContext={props.InfoCard?.focusContext}
                        zone={props.Target}
                        inZoneCard={true}
                        showZoneName={false}
                        showHeader={false}
                        style={{
                            maxWidth: 500,
                            maxHeight: 100,
                        }}
                    />
                </ListItem>
                <div className={"FullsizeZoneOverviewListItem--Wrap"}>
                    <ListItem className={"FullsizeZoneOverviewListItem--ListItem"}>
                        <Button
                            onClick={() => {
                                setEditorOpen(true);
                            }}
                            className={"FullsizeZoneOverviewListItem--ListItem"}
                        >
                            Edit Zone
                        </Button>
                        <ZoneProfileEditor
                            open={editorOpen}
                            onClose={() => {
                                setEditorOpen(false);
                            }}
                            zone={props.Target}
                        />
                    </ListItem>
                    {SessionHandler.admin && (
                        <ListItem className={"FullsizeZoneOverviewListItem--ListItem"}>
                            <Typography variant={"subtitle1"} style={{ color: "#757575", marginBottom: "4vh" }}>
                                {`ID: ${props.Target.id}`}
                            </Typography>
                        </ListItem>
                    )}
                    {SessionHandler.admin && (
                        <Button
                            className={"DownloadGEOJSON_Button"}
                            onClick={() => {
                                const template = {
                                    type: "Feature",
                                    geometry: {
                                        type: "Polygon",
                                        coordinates: [props.Target.polygon.map((point) => [point.lng, point.lat])],
                                    },
                                    properties: {
                                        name: props.Target.name,
                                        id: props.Target.id,
                                    },
                                };
                                downloadObjectAsJson(template, `${props.Target.name}.geo`);
                            }}
                        >
                            Download GeoJSON
                        </Button>
                    )}
                </div>
                {/* </ThemeProvider> */}
                {/* </StyledEngineProvider> */}
            </List>
        </DynamicGraphContext.Provider>
    );
});

function downloadObjectAsJson(exportObj, exportName) {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj))}`;
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `${exportName}.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}
