import SettingsIcon from "@mui/icons-material/Settings";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { SessionHandler } from "verdiapi";

import { IconButtonWithTooltip } from "../../components/generic/IconButtonWithTooltip";
import { DesktopAdminSettingsNavList } from "./NavLinks";

/**
 * Settings menu
 * A gear icon which behaves differently for different users
 * - non-admins: directs to the settings page
 * - admins: opens a dropdown menu with the settings options (including going to settings page)
 */
export function SettingsMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const { t } = useTranslation("modules");

    const isAdmin = Boolean(SessionHandler.admin);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            {isAdmin ? (
                <>
                    <IconButtonWithTooltip
                        onClick={handleOpen}
                        icon={<SettingsIcon />}
                        title={t("navigation.settingsMenu.settings")}
                    />
                    {isAdmin && (
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            style={{ zIndex: 1400 }}
                            open={open}
                            onClose={handleClose}
                        >
                            {DesktopAdminSettingsNavList.map((navItem) => (
                                <MenuItem
                                    key={`SettingsMenu_${navItem.url}`}
                                    component={Link}
                                    to={navItem.url}
                                    onClick={handleClose}
                                >
                                    {navItem.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    )}
                </>
            ) : (
                // Non-admins cannot access the menu items so directly link to settings page
                <IconButtonWithTooltip
                    onClick={() => {
                        navigate("/settings");
                    }}
                    icon={<SettingsIcon />}
                    title={t("navigation.settingsMenu.settings")}
                />
            )}
        </>
    );
}
