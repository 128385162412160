import Battery30Icon from "@mui/icons-material/Battery30";
import Battery80Icon from "@mui/icons-material/Battery80";
import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { withTranslation } from "react-i18next";

import { LightTooltip } from "../../generic/LightTooltip/LightTooltip";

class BatteryStatusIcon extends React.Component {
    batteryWarningLevel;

    constructor(props) {
        super(props);
        let batteryWarningLevel = this.props.batteryWarningLevel;
        if (!batteryWarningLevel && this.props.Target) {
            if (this.props.Target.sproutNetworkInfo?.batteryVoltage) {
                const voltage = this.props.Target.sproutNetworkInfo?.batteryVoltage;
                if (voltage > 6) {
                    if (voltage < 7.25) {
                        batteryWarningLevel = 4;
                    } else if (voltage < 8) {
                        batteryWarningLevel = 3;
                    } else if (voltage < 8.2) {
                        batteryWarningLevel = 2;
                    } else {
                        batteryWarningLevel = 1;
                    }
                } else if (voltage < 1.5 * 2) {
                    batteryWarningLevel = 4;
                } else if (voltage < 1.55 * 2) {
                    batteryWarningLevel = 3;
                } else if (voltage < 1.6 * 2) {
                    batteryWarningLevel = 2;
                } else {
                    batteryWarningLevel = 1;
                }
            } else if (this.props.Target.saplingNetworkInfo) {
                const battPercent = this.props.Target.saplingNetworkInfo?.batteryPercent;
                if (battPercent !== undefined) {
                    if (battPercent < 0.07) {
                        batteryWarningLevel = 4;
                    } else if (battPercent < 0.3) {
                        batteryWarningLevel = 3;
                    } else if (battPercent < 0.7) {
                        batteryWarningLevel = 2;
                    } else {
                        batteryWarningLevel = 1;
                    }
                }
            } else if (this.props.Target.device_stats) {
                const batt = this.props.Target.device_stats?.batt;
                if (batt < 1.3) {
                    batteryWarningLevel = 4;
                } else if (batt < 1.4) {
                    batteryWarningLevel = 3;
                } else if (batt < 1.6) {
                    batteryWarningLevel = 2;
                } else {
                    batteryWarningLevel = 1;
                }
            }
        }
        this.batteryWarningLevel = batteryWarningLevel;
    }

    render() {
        const { t } = this.props;
        let BatteryIcon = BatteryUnknownIcon;
        let BatteryColor = "#adadad";
        let BatteryText = this.props.t("text.full.noInfo");
        let BatteryShortText = t("text.short.noInfo");
        if (this.batteryWarningLevel) {
            if (this.batteryWarningLevel > 3) {
                BatteryIcon = BatteryAlertIcon;
                BatteryColor = "#e65100";
                BatteryText = t("text.full.batteryLow");
                BatteryShortText = t("low", { ns: "common", keyPrefix: "status" });
            } else if (this.batteryWarningLevel > 2) {
                BatteryIcon = Battery30Icon;
                BatteryColor = "#ffa000";
                BatteryText = t("text.full.batteryOk");
                BatteryShortText = t("ok", { ns: "common", keyPrefix: "status" });
            } else if (this.batteryWarningLevel > 1) {
                BatteryIcon = Battery80Icon;
                BatteryColor = "#43a047";
                BatteryText = t("text.full.batteryGood");
                BatteryShortText = t("good", { ns: "common", keyPrefix: "status" });
            } else {
                BatteryIcon = BatteryFullIcon;
                BatteryColor = "#43a047";
                BatteryText = t("text.full.batteryFull");
                BatteryShortText = t("full", { ns: "common", keyPrefix: "status" });
            }
        }
        // eslint-disable-next-line no-unused-vars
        const { t: _, tReady, ...propsToPass } = this.props;
        const passedProps = { ...propsToPass };
        delete passedProps.listItem;
        delete passedProps.batteryWarningLevel;
        delete passedProps.Target;
        delete passedProps.showDetails;
        if (this.props.listItem) {
            return (
                <ListItem {...passedProps}>
                    <ListItemIcon>
                        <BatteryIcon style={{ color: BatteryColor }} />
                    </ListItemIcon>
                    <ListItemText primary={t("labels.full.batteryLevel")} secondary={BatteryText} />
                </ListItem>
            );
        }
        if (this.props.showDetails) {
            return (
                <div {...passedProps}>
                    <LightTooltip title={BatteryText}>
                        <IconButton>
                            <BatteryIcon style={{ color: BatteryColor }} />
                        </IconButton>
                    </LightTooltip>
                    <Typography variant={"caption"}>{t("labels.short.batteryLevel")}</Typography>
                    <Typography variant={"caption"}>{BatteryShortText}</Typography>
                </div>
            );
        }
        return (
            <LightTooltip title={BatteryText}>
                <IconButton {...passedProps}>
                    <BatteryIcon style={{ color: BatteryColor }} />
                </IconButton>
            </LightTooltip>
        );
    }
}

export default withTranslation(["icons", "common"], { keyPrefix: "statusIcons.batteryStatusIcon" })(BatteryStatusIcon);
