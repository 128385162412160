import { Graphics } from "@pixi/react";
import { BufferedAction, sleep } from "gardenspadejs/dist/general";
import React from "react";
import { EventHandler } from "verdiapi";
import { GenerateUID } from "verdiapi/dist/HelperFunctions";
import { HistoricalDataBase } from "verdiapi/dist/Models/HistoricalData/HistoricalDataBase";

import GlobalOptions from "../../../../utils/GlobalOptions";
import { DynamicGraphContext } from "../DynamicGraphUtility";

const SingleEventsBars = [];

const YValueTransformsPerDataType = {
    sensoterra: (v) => v / 100,
    irrometer: (v) => (100 - v) / 100,
};

function getYValueForDataType(dataType) {
    return YValueTransformsPerDataType[dataType.toLowerCase()];
}
const cachedDatabases = {
    key: {
        users: [],
        db: undefined,
        flaggedToDelete: false,
    },
};
const cleanDBCacheAction = new BufferedAction(
    () => {
        let subsequentCleanupRequired = false;
        Object.keys(cachedDatabases).forEach((key) => {
            if (cachedDatabases[key].users.length > 0) {
                cachedDatabases[key].flaggedToDelete = false;
            }
            if (cachedDatabases[key].users.length === 0) {
                subsequentCleanupRequired = true;
                if (
                    cachedDatabases[key].flaggedToDelete === false ||
                    cachedDatabases[key].flaggedToDelete === undefined
                ) {
                    cachedDatabases[key].flaggedToDelete = Date.now();
                } else if (Date.now() - cachedDatabases[key].flaggedToDelete.valueOf() > 1000 * 60 * 15) {
                    delete cachedDatabases[key];
                }
            }
        });
        const keysFlaggedForDeletion = Object.keys(cachedDatabases).filter(
            (key) => cachedDatabases[key].flaggedToDelete,
        );
        // Make sure we don't go above the max number of cached graph lines
        if (keysFlaggedForDeletion.length > GlobalOptions.maxCachedGraphLines) {
            keysFlaggedForDeletion
                .sort(
                    (a, b) =>
                        cachedDatabases[a].flaggedToDelete.valueOf() - cachedDatabases[b].flaggedToDelete.valueOf(),
                )
                .splice(GlobalOptions.maxCachedGraphLines)
                .forEach((key) => {
                    delete cachedDatabases[key];
                });
        }
        if (subsequentCleanupRequired) {
            cleanDBCacheAction.trigger();
        }
    },
    1000 * 30,
    false,
    true,
);

export default class TrendGraphLine extends React.Component {
    automationGraphicsObj = undefined;

    g = undefined;

    k = 0;

    /*
     * @type {HistoricalDataBase}
     */
    zoneHistoryDatabase;

    constructor(props) {
        super(props);

        this.uid = GenerateUID("StaticMoistureLine");
        SingleEventsBars.push(this);
        this.state = {
            lineDataType: this.props.sensor.dataType,
            lineColor: `0x${this.props.sensor.color.substring(1)}`,
        };
        this.props.addToSensor({
            yAxisValueForDataType: (t) => this.zoneHistoryDatabase.data.getClosestPointToValue(t, "none"),
        });

        this.props.sensor.getYValueForDataType = (t) => {
            const nearestPoint = this.zoneHistoryDatabase.data.getClosestPointToValue(t, "none");
            return {
                nearestPoint: nearestPoint,
                pixelYValue: nearestPoint
                    ? this.optionsForGraphType.getYPercentage(
                          nearestPoint.getDeviceData(this.props.sensor.id)[this.state.lineDataType],
                      ) * this.props.height
                    : null,
            };
        };
        this.ownDBKey = `${this.props.sensor.id}__${this.state.lineDataType}__${this.props.sensor.uid || ""}`;
        if (!cachedDatabases[this.ownDBKey]) {
            cachedDatabases[this.ownDBKey] = {
                users: [],
                db: new HistoricalDataBase([this.props.sensor.id], [this.state.lineDataType]),
                flaggedToDelete: new Date(0),
            };
            cachedDatabases[this.ownDBKey].db.acceptableZones = this.props.sensor.connectedZones;
        }
        this.zoneHistoryDatabase = cachedDatabases[this.ownDBKey].db;
        cachedDatabases[this.ownDBKey].users.push(this.uid);
        this.zoneHistoryDatabase.worryAboutPosition = false;
        cleanDBCacheAction.trigger();
    }

    componentDidMount() {
        if (!cachedDatabases[this.ownDBKey]) {
            cachedDatabases[this.ownDBKey] = {
                users: [],
                db: this.zoneHistoryDatabase,
                flaggedToDelete: new Date(0),
            };
        }
        if (!cachedDatabases[this.ownDBKey].users.includes(this.uid)) {
            cachedDatabases[this.ownDBKey].users.push(this.uid);
        }

        cleanDBCacheAction.trigger();
        setTimeout(() => {
            if (!cachedDatabases[this.ownDBKey].users.includes(this.uid)) {
                cachedDatabases[this.ownDBKey].users.push(this.uid);
            }
        }, 1000);
        let intitialLoadDelay = 10 + Math.random() * 20;
        if (Object.keys(this.context.lines).length > 20) {
            intitialLoadDelay = 10 + Math.random() * 800;
        } else if (Object.keys(this.context.lines).length > 5) {
            intitialLoadDelay = 10 + Math.random() * 200;
        }
        // staggers data loading
        setTimeout(() => {
            this.zoneHistoryDatabase
                .getData(new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 10), this.context.endTime)
                .then(() => {
                    this.redraw();
                })
                .catch((e) => {
                    console.warn("error fetching schedule history");
                    console.warn(e);
                });
            // load spreader v
        }, intitialLoadDelay);

        const action = async () => {
            await sleep(10 + Math.random() * 500);
            this.zoneHistoryDatabase
                .getData(
                    new Date(this.context.startTime.valueOf()),
                    new Date(Math.ceil(Date.now() / (60000 * 60 * 24)) * 60000 * 60 * 24),
                )
                .then(() => {
                    this.redraw();
                })
                .catch((e) => {
                    console.warn("error fetching schedule history");
                    console.warn(e);
                });
        };

        const bufferedAction = new BufferedAction(action, 1500, true, false);
        if (this.optionsForGraphType) {
            this.optionsForGraphType.onCurrentRangeChange.addListener(() => {
                this.redraw();
            }, this.uid);
        }
        this.context.lines[this.props.sensor.uid].getNearestValue = (t) =>
            this.zoneHistoryDatabase.data.getClosestPointToValue(t, "none")?.getDeviceData(this.props.sensor.id)?.[
                this.state.lineDataType
            ];
        this.context.onDateRangeChange.addListener(() => {
            bufferedAction.trigger();
            this.redraw();
        }, this.uid);
        this.context.onFocusedLinesChanged.addListener(() => {
            this.redraw();
        }, this.uid);
    }

    componentWillUnmount() {
        if (this.optionsForGraphType && this.optionsForGraphType.requiredRanges[this.props.sensor.uid]) {
            delete this.optionsForGraphType.requiredRanges[this.props.sensor.uid];
        }
        EventHandler.disposeOfAllHooksForUID(this.uid);
        this.context.unRegisterLine(this.uid);
        if (cachedDatabases[this.ownDBKey]) {
            cachedDatabases[this.ownDBKey].users = cachedDatabases[this.ownDBKey].users.filter((u) => u === this.uid);
        }
        cleanDBCacheAction.trigger();
    }

    get optionsForGraphType() {
        if (this.context.staticGraphDictionary[this.props.sensor.sensorType]) {
            return this.context.staticGraphDictionary[this.props.sensor.sensorType];
        }
        this.context.addGraphType(this.props.sensor.sensorType);

        return this.context.staticGraphDictionary[this.props.sensor.sensorType];
    }

    _doRedraw() {
        if (!this.g) {
            return;
        }
        this.k++;
        // smoothingVarianceThreshold is the expected variance between similar data points. When smoothing the graph,
        // datapoints that are different by more than this amount will not be used.
        // default is zero
        const smoothingVarianceThreshold = this.optionsForGraphType.smoothingVarianceThreshold;
        const dataKey = this.zoneHistoryDatabase.getDataKeyForModel(this.props.sensor.id, this.state.lineDataType);
        const interpolation = this.optionsForGraphType.interpolation || "linear";
        const maxInterpolationTime = this.optionsForGraphType.maxInterpolationTime || 1000 * 60 * 60 * 24 * 1000;

        /**
         * The bin size for creating a rough histogram to figure out what range needs to be shown
         * @type {number}
         */
        const binSizeForMinMax =
            Math.abs(this.optionsForGraphType.typicalRange[1] - this.optionsForGraphType.typicalRange[0]) / 10 || 10;
        try {
            this.g.clear();
            this.g.endFill();

            const valueBins = {};
            let totalPointCount = 0;
            let minPoint;
            let maxPoint = 0;
            // Return y value on canvas based on raw value from sensor
            const valueFromNumber = (number) => {
                if (number || number === 0) {
                    totalPointCount++;
                    const binnedNumber = Math.round(number / binSizeForMinMax) * binSizeForMinMax;
                    valueBins[binnedNumber] = (valueBins[binnedNumber] || 0) + 1;
                    minPoint = Math.min(number, minPoint === undefined ? number : minPoint);
                    if (maxPoint === undefined || number > maxPoint) {
                        maxPoint = number;
                    }
                }
                if (this.optionsForGraphType) {
                    return this.optionsForGraphType.getYPercentage(number) * this.props.height;
                }
                return getYValueForDataType(this.props.sensor.sensorType)(number) * this.props.height;
            };

            if (this.props.sensor.automationRange && this.automationGraphicsObj) {
                const topY = this.props.height - valueFromNumber(this.props.sensor.automationRange[0]);
                const botY = this.props.height - valueFromNumber(this.props.sensor.automationRange[1]);
                this.automationGraphicsObj.clear();
                this.automationGraphicsObj.zIndex = -100;
                this.automationGraphicsObj.beginFill(0xd3eafd);
                this.automationGraphicsObj.lineStyle("d3eafd");
                this.automationGraphicsObj.drawRect(0, 0, this.props.width, botY);
                this.automationGraphicsObj.beginFill(0xffe0b2);
                this.automationGraphicsObj.lineStyle("FFE0B2");
                this.automationGraphicsObj.drawRect(0, topY, this.props.width, this.props.height - topY);
                this.automationGraphicsObj.endFill();
            }
            this.g.beginFill(0xffffff, 0);
            let lineColor = this.state.lineColor;
            let lineWidth = 2.0;
            this.g.zIndex = 2;
            if (this.context.lines[this.props.sensor.uid].focused === false) {
                lineColor = 0xa6a6a6;
                this.g.zIndex = 50;
                lineWidth = 0.5;
            } else {
                this.g.zIndex = 100;
            }
            this.g.lineStyle(lineWidth, lineColor, 1);

            const startOfGraph = this.context.startTime;
            const endOfGraph = this.context.endTime;
            const rangeOfGraph = endOfGraph.valueOf() - startOfGraph.valueOf();

            const getXOfDate = (date) => ((date.valueOf() - startOfGraph.valueOf()) / rangeOfGraph) * this.props.width;

            let finalDataPoint;
            if (this.zoneHistoryDatabase.data.value.length > 0) {
                finalDataPoint = this.zoneHistoryDatabase.data.value[this.zoneHistoryDatabase.data.value.length - 1];
            }
            // Initialize the first data point
            let nextDataPoint = this.zoneHistoryDatabase.data.getClosestPointToValue(endOfGraph.valueOf(), "higher");
            // If there is no point that is just past the end of the graph, use the most recent data point.
            if (!nextDataPoint && finalDataPoint) {
                nextDataPoint = finalDataPoint;
            }
            if (!nextDataPoint) {
                this.g.endFill();
                return;
            }

            const leadingData = [];
            const trailingData = [];
            let trailingPoint = nextDataPoint;
            let v = nextDataPoint.getDeviceData(this.props.sensor.id)[this.state.lineDataType];
            let lastX;
            let lastDate = nextDataPoint.date;

            this.g.moveTo(
                getXOfDate(nextDataPoint.date),
                this.props.height -
                    valueFromNumber(nextDataPoint.getDeviceData(this.props.sensor.id)[this.state.lineDataType]),
            );
            const dataForCSV = [];
            if (nextDataPoint) {
                dataForCSV.push(nextDataPoint.data);
            }
            let continueDrawing = true;
            const incrementCurrentPoint = () => {
                leadingData.unshift({
                    date: nextDataPoint.date,
                    value: nextDataPoint.data[dataKey],
                });
                while (leadingData.length > 9) {
                    leadingData.pop();
                }
                nextDataPoint = nextDataPoint.prevPoint;
                while (nextDataPoint && nextDataPoint.data[dataKey] === undefined) {
                    nextDataPoint = nextDataPoint.prevPoint;
                }
                if (nextDataPoint) {
                    v = nextDataPoint.data[dataKey];
                    dataForCSV.push(nextDataPoint.data);
                }
                trailingData.pop();
                while (trailingData.length < 9 && trailingPoint.prevPoint) {
                    trailingData.unshift({
                        date: trailingPoint.date,
                        value: trailingPoint.data[dataKey],
                    });
                    trailingPoint = trailingPoint.prevPoint;
                    while (trailingPoint && trailingPoint.data[dataKey] === undefined) {
                        trailingPoint = trailingPoint.prevPoint;
                    }
                }
            };
            while (nextDataPoint && continueDrawing) {
                if (!nextDataPoint) {
                    break;
                }
                // If this point is outside the frame, we should still draw this one, but exit the loop after this point.
                // graph display frame:✓
                //   x  ✓ |  ✓ ✓ ✓   ✓   ✓  ✓ | ✓
                //      ^ we include this point, but don't want to draw back any further
                if (nextDataPoint.date.valueOf() < startOfGraph) {
                    continueDrawing = false;
                }
                let dataPointValue = v;
                const dataPointDate = nextDataPoint.date.valueOf();
                let totalWeight = 1;

                // This handles smoothing. smoothingVarianceThreshold represents the amount of run-to-run variance we expect from a sensor,
                // and therefore what datapoints should be considered "close enough" to matter for data smoohting.
                // data points that are outside run-to-run variance will be skipped when smoothing
                // if smoothingVarianceThreshold is zero, then no points will be included when smoothing so we just skip the following code.
                if (smoothingVarianceThreshold > 0) {
                    /*
                     * this essentially calculates the weighted average of data points near the current one,
                     * where the weight of each data point decreases quadratically the further you get from the
                     * current point.
                     */

                    // keep track of our total
                    let runningTotal = dataPointValue;

                    // keeps track of what we need to divide the sum of all the data points we are averaging by
                    totalWeight = 1;

                    // this is how much influence the next data point should have on the position of the smoothed data point.
                    // every time we use another data point for smoothing, this weightOfNextPoint is reduced.
                    let weightOfNextPoint = 0.97;
                    for (let i = 0; i < Math.min(leadingData.length, trailingData.length); i++) {
                        const leadIndex = i;
                        const trailIndex = trailingData.length - 1 - i;
                        if (
                            Math.abs(leadingData[leadIndex].value - v) < smoothingVarianceThreshold &&
                            Math.abs(trailingData[trailIndex].value - v) < smoothingVarianceThreshold
                        ) {
                            weightOfNextPoint = weightOfNextPoint * weightOfNextPoint * 0.97;
                            runningTotal += leadingData[leadIndex].value * weightOfNextPoint;
                            runningTotal += trailingData[trailIndex].value * weightOfNextPoint;

                            // add 2x the weightOfNextPoint becuase we are adding 2 points to the running total.
                            totalWeight += weightOfNextPoint * 2;
                        } else {
                            break;
                        }
                    }

                    // use the weight and running total to figure out the smoothed value:
                    dataPointValue = runningTotal / totalWeight;
                }

                const x = getXOfDate(new Date(dataPointDate));
                const value = valueFromNumber(dataPointValue);
                const y = this.props.height - value;
                if (Math.abs(lastDate.valueOf() - dataPointDate.valueOf()) < maxInterpolationTime) {
                    if (interpolation === "linear") {
                        this.g.lineTo(x, y);
                    } else if (interpolation === "square") {
                        if (lastX !== undefined) {
                            this.g.lineTo(lastX, y);
                        }
                        this.g.lineTo(x, y);
                    }
                } else {
                    this.g.moveTo(x, y);
                }

                lastX = x;
                lastDate = dataPointDate;
                incrementCurrentPoint();
                if (!nextDataPoint) {
                    break;
                }
            }

            this.context.filteredGraphPoints[this.props.sensor.uid] = {
                id: this.props.sensor.uid,
                name: this.context.lines[this.props.sensor.uid].name,
                sensorType: this.context.lines[this.props.sensor.uid].sensorType,
                dataType: this.context.lines[this.props.sensor.uid].dataType,
                data: dataForCSV,
            };

            this.g.endFill();
            if (
                this.optionsForGraphType &&
                (!this.optionsForGraphType.requiredRanges[this.props.sensor.uid] ||
                    this.optionsForGraphType.requiredRanges[this.props.sensor.uid].toString() !==
                        [minPoint, maxPoint].toString())
            ) {
                const boundaries = getBoundariesFromHistogram(valueBins, totalPointCount, binSizeForMinMax, {
                    min: minPoint,
                    max: maxPoint,
                });

                if (!boundaries) {
                    this.optionsForGraphType.requiredRanges[this.props.sensor.uid] = [minPoint, maxPoint];
                } else {
                    this.optionsForGraphType.requiredRanges[this.props.sensor.uid] = boundaries;
                }
                this.optionsForGraphType.triggerPotentialYAxisChange.trigger();
            }
        } catch (e) {
            console.warn(e);
        }
    }

    redraw(g = undefined) {
        if (g) {
            this.g = g;
        }
        if (!this.g) {
            return;
        }
        this.k++;
        const temp = this.k;
        window.requestAnimationFrame(() => {
            if (temp !== this.k) {
                return;
            }
            this._doRedraw();
        });
    }

    render() {
        return (
            <>
                <Graphics
                    y={this.props.y || 0}
                    draw={(g) => {
                        this.redraw(g);
                    }}
                    options={{
                        interactive: this.props.interactive,
                    }}
                />
                {this.props.sensor.automationRange && (
                    <Graphics
                        y={this.props.y || 0}
                        draw={(g) => {
                            this.automationGraphicsObj = g;
                            this.redraw();
                        }}
                        options={{
                            interactive: false,
                        }}
                    />
                )}
            </>
        );
    }
}

function getBoundariesFromHistogram(histogram, totalPointCount, binSize, options) {
    const allBins = Object.keys(histogram).map((k) => parseFloat(k));
    allBins.sort((a, b) => a - b);
    const binCount = allBins.length;
    if (binCount < 2 || totalPointCount < 10) {
        return undefined;
    }

    const numPointsToIgnore = Math.round(5 + totalPointCount * 0.01);
    let pointsSeenSoFar = 0;
    let index = -1;
    while (pointsSeenSoFar < numPointsToIgnore && index < binCount) {
        index++;
        pointsSeenSoFar += histogram[allBins[index]];
    }
    let min = allBins[index] - binSize / 2;
    index = -1;
    pointsSeenSoFar = 0;
    while (pointsSeenSoFar < numPointsToIgnore && index < binCount) {
        index++;
        pointsSeenSoFar += histogram[allBins[binCount - index - 1]];
    }
    let max = allBins[binCount - index - 1] + binSize / 2;

    if (max > min) {
        const range = max - min;
        const padding = Math.max(range * 0.15, binSize * 2);

        if (options.min !== undefined && Math.abs(min - options.min) < padding * 2) {
            min = options.min;
        } else {
            min -= padding;
        }
        if (options.max !== undefined && Math.abs(max - options.max) < padding * 2) {
            max = options.max;
        } else {
            max += padding;
        }
        return [min, max];
    }
    return undefined;
}

TrendGraphLine.contextType = DynamicGraphContext;

// TrendGraphLine.propTypes = {
//     height: PropTypes.number,
//     interactive: PropTypes.bool,
//     sensor: PropTypes.object,
//     endTime: PropTypes.instanceOf(Date),
//     startTime: PropTypes.instanceOf(Date),
//     width: PropTypes.number,
//     addToSensor: PropTypes.func,
// };
