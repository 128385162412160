/* eslint-disable import/no-webpack-loader-syntax,import/no-extraneous-dependencies,import/extensions,import/no-unresolved */
import "./AllComponentDemos.scss";

import { Button, Typography } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";

import AsyncTaskButtonDemo from "../AsyncTaskButton/AsyncTaskButtonDemo";
import BetaTagDemo from "../BetaTag/BetaTagDemo";
import CircleStepperDemo from "../CircleStepper/CircleStepperDemo";
import ConfirmChoiceButtonDemo from "../ConfirmChoiceButton/ConfirmChoiceButtonDemo";
import GraphLegendChipDemo from "../GraphLegendChip/GraphLegendChipDemo";
import IconDatePickerDemo from "../IconDatePicker/IconDatePickerDemo";
import JSONDropdownDemo from "../JSONDropdown/JSONDropdownDemo";
import LightTooltipDemo from "../LightTooltip/LightTooltipDemo";
import NDVIChipDemo from "../NDVIChip/NDVIChipDemo";
import NumericFieldWithUnitDemo from "../NumericFieldWithUnit/NumericFieldWithUnitDemo";
import TextFieldAlignmentOverrideDemo from "../TextFieldAlignmentOverride/TextFieldAlignmentOverrideDemo";
import WarningHeaderDemo from "../WarningHeader/WarningHeaderDemo";

export default function AllComponentDemos({ className, ...props }) {
    const finalClassName = `${className || ""} AllComponentDemos`;

    return (
        <div className={finalClassName} {...props}>
            <SingleComponentDemo {...JSONDropdownDemo} />
            <SingleComponentDemo {...GraphLegendChipDemo} />
            <SingleComponentDemo {...ConfirmChoiceButtonDemo} />
            <SingleComponentDemo {...IconDatePickerDemo} />
            <SingleComponentDemo {...CircleStepperDemo} />
            <SingleComponentDemo {...NumericFieldWithUnitDemo} />
            <SingleComponentDemo {...TextFieldAlignmentOverrideDemo} />
            <SingleComponentDemo {...NDVIChipDemo} />
            <SingleComponentDemo {...LightTooltipDemo} />
            <SingleComponentDemo {...WarningHeaderDemo} />
            <SingleComponentDemo {...AsyncTaskButtonDemo} />
            <SingleComponentDemo {...BetaTagDemo} />
        </div>
    );
}

function SingleComponentDemo({ component, name, readme, source }) {
    const [ReadmeContents, setReadmeContents] = React.useState("Loading...");
    const [expanded, setExpanded] = React.useState(false);
    if (readme) {
        fetch(readme)
            .then((response) => response.text())
            .then((text) => {
                setReadmeContents(text);
            })
            .catch((error) => {
                console.warn(`Error loading readme for ${name}`, error);
            });
    }

    const CapsComponent = component;
    return (
        <div className={"SingleCompDemo"}>
            <div className={"SingleCompDemo_Demo"}>
                <CapsComponent />
            </div>
            <div className={"SingleCompDemo_Description"}>
                <Typography variant={"h4"}>{name}</Typography>
                <Markdown>{ReadmeContents}</Markdown>
            </div>
            <div className={`SingleCompDemo_Source SingleCompDemo_Source--${expanded ? "expanded" : "collapsed"}`}>
                <Markdown>{`\`\`\` ${source} \`\`\``}</Markdown>
                <Button
                    className={"ExpandSourceButton"}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    {" "}
                    {expanded ? "collapse" : "expand"}{" "}
                </Button>
            </div>
        </div>
    );
}
