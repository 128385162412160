import "./SproutVerification.scss";
import "./LiveIcon.scss";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import { GenerateApproximateTime } from "gardenspadejs/dist/dateHelpers";
import React from "react";
import { getVerdiConfiguration, PacketEncodings, VerificationCodeByStatus, VerificationStatusByCode } from "verditypes";

import BetaTag from "../../generic/BetaTag/BetaTag";
import { ExplainingSproutVerification } from "../HelpDialogues/ExplainingSproutVerification";
import SproutIndividualVerification from "./SproutIndividualVerification";
import { ValveCloseDroplet, ValveOpenDroplet, VerificationIconByStatus } from "./VerificationIcons";
import { VerificationPerformanceOverview } from "./VerificationPerformanceOverview";

function getVerificationStatus(ver) {
    // eslint-disable-next-line no-nested-ternary
    return ver.pendingData ? "pending" : ver.successful ? "successful" : "error";
}

// noinspection JSUnusedLocalSymbols
export default function SproutVerification(props) {
    if (!props.device) {
        return <Typography>No device prop</Typography>;
    }
    if (!props.device?.sproutIrrigationVerification?.verificationsByValveState) {
        return null;
    }
    if (
        Object.keys(props.device?.sproutIrrigationVerification?.verificationsByValveState).filter((v) =>
            Boolean(props.device?.sproutIrrigationVerification?.verificationsByValveState[v]),
        ).length === 0
    ) {
        return null;
    }
    let currentStateIndex = -1;
    if (props.device.valveState && Array.isArray(props.device.valveState.open)) {
        currentStateIndex = PacketEncodings.ValveState.encode(...props.device.valveState.open);
    }
    const [curSelectedIndex, setSelectedIndex] = React.useState(0b11);
    React.useEffect(() => {
        let startingIndex = 0b11;
        if (props.device?.sproutIrrigationVerification?.verificationsByValveState) {
            const verByValveState = props.device?.sproutIrrigationVerification?.verificationsByValveState;
            startingIndex = [0, 1, 2, 3].reduce((champ, challenger) => {
                if (!verByValveState[challenger]) {
                    return champ;
                }
                if (!verByValveState[champ]) {
                    return challenger;
                }
                if (
                    VerificationCodeByStatus[getVerificationStatus(verByValveState[champ])] >=
                    VerificationCodeByStatus[getVerificationStatus(verByValveState[challenger])]
                ) {
                    return champ;
                }
                return challenger;
            }, 0b11);
            if (startingIndex === undefined) {
                startingIndex = 0b11;
            }
        }
        if (startingIndex !== 0b11) {
            setSelectedIndex(startingIndex);
        }
    }, []);
    /**
     * @type {SproutVerificationInfo}
     */
    const verificationInfo = props.device.sproutIrrigationVerification;
    const doubleValveDevice = getVerdiConfiguration(props.device.type).valveCount === 2;
    const verificationsOfImportance = doubleValveDevice ? [0b00, 0b01, 0b10, 0b11] : [0b00, 0b11];

    // eslint-disable-next-line no-unused-vars
    const overallStatus =
        verificationsOfImportance.reduce((runningStatus, verificationIndex) => {
            /**
             * @type {SproutVerificationInstance}
             */
            const ver = verificationInfo.verificationsByValveState[verificationIndex];
            if (!ver) {
                return runningStatus;
            }
            const ownStatus = getVerificationStatus(ver);
            // let ownStatus = ver.pendingData ? "pending" : ver.successful ? "successful" : "error";
            const maxStatusScore = Math.max(
                VerificationCodeByStatus[ownStatus],
                VerificationCodeByStatus[runningStatus] || -10000,
            );
            return VerificationStatusByCode[maxStatusScore];
        }) || "inconclusive";
    return (
        <>
            <VerificationPerformanceOverview device={props.device} doubleValveDevice={doubleValveDevice} />

            <div className={"SproutVerificationRoot"}>
                <Typography variant={"h4"} className={"SproutVerificationRoot__Title"}>
                    Valve State Verifications
                    <div style={{ height: "100%", width: "5px" }} />
                    <BetaTag />
                </Typography>
                <div className={"SproutVerificationRoot__HelpIcon"}>
                    <ExplainingSproutVerification>
                        <InfoOutlinedIcon />
                    </ExplainingSproutVerification>
                </div>
                <div className={"SproutVerificationTabs"}>
                    {/* <div className={"SproutVerificationOverallStatus"}> */}
                    {/*    <VerificationIconByStatus status={overallStatus} /> */}
                    {/* </div> */}
                    {verificationsOfImportance.map((index) => (
                        <IndividualVerificationTab
                            key={index}
                            verificationIndex={index}
                            doubleValveDevice={doubleValveDevice}
                            selected={curSelectedIndex === index}
                            onClick={() => {
                                setSelectedIndex(index);
                            }}
                            currentlyActive={index === currentStateIndex}
                            wideTab={verificationsOfImportance.length <= 2}
                            individualVerification={verificationInfo.verificationsByValveState[index]}
                        />
                    ))}
                </div>
                <div className={"SproutVerificationTabPanel"}>
                    <SproutIndividualVerification
                        individualVerification={verificationInfo.verificationsByValveState[curSelectedIndex]}
                        verificationIndex={curSelectedIndex}
                        doubleValve={doubleValveDevice}
                        selected={true}
                        currentlyActive={currentStateIndex === curSelectedIndex}
                    />
                </div>
            </div>
        </>
    );
}

/**
 *
 * @param {SproutVerificationInstance} individualVerification
 * @param {boolean} doubleValveDevice
 * @param {boolean} selected
 * @constructor
 */
function IndividualVerificationTab({
    individualVerification,
    verificationIndex,
    doubleValveDevice,
    selected,
    onClick,
    wideTab,
    // specifically is this the state the device is currently in
    currentlyActive,
}) {
    const valvesIntendedOpen = PacketEncodings.ValveState.decode(verificationIndex).open;
    let footerText;
    // if it's a double valve device with mixed valve states
    if (doubleValveDevice && valvesIntendedOpen[0] !== valvesIntendedOpen[1]) {
        footerText = "mix";
    } else if (valvesIntendedOpen[0]) {
        footerText = "open";
    } else {
        footerText = "close";
    }

    let className = ` IndividualVerificationTab IndividualVerificationTab--${selected ? "selected" : "unselected"} `;
    className += ` IndividualVerificationTab--${wideTab ? "wide" : "narrow"} `;
    className += ` IndividualVerificationTab--${currentlyActive ? "active" : "inactive"} `;

    let ownStatus;
    if (individualVerification === undefined) {
        ownStatus = "inconclusive";
    } else if (individualVerification?.pendingData) {
        ownStatus = "pending";
    } else if (individualVerification?.successful) {
        ownStatus = "successful";
    } else {
        ownStatus = "error";
    }

    let lastUpdatedText = "never";
    if (individualVerification) {
        lastUpdatedText = GenerateApproximateTime(
            new Date(individualVerification?.date?.valueOf() || 0),
            undefined,
            false,
            true,
        );
        if (lastUpdatedText.includes("today at ")) {
            lastUpdatedText = lastUpdatedText.replace("today at ", "");
        }
        if (lastUpdatedText.includes("yesterday")) {
            lastUpdatedText = "yesterday";
        }
        if (lastUpdatedText.includes(" min ")) {
            lastUpdatedText = lastUpdatedText.replace(" min ", "m ");
        }
    }
    let IrrigationVerb = "Attempted";
    if (ownStatus === "successful") {
        IrrigationVerb = "Passed";
    } else if (ownStatus === "error") {
        IrrigationVerb = "Failed";
    } else if (ownStatus === "pending") {
        IrrigationVerb = "Pending";
    }
    return (
        <div className={className} onClick={onClick}>
            <div className={`tabElement PassFailContainer VerificationStatusIcon VerificationStatusIcon--${ownStatus}`}>
                <VerificationIconByStatus status={ownStatus} className={"TabVerificationIcon"} />
            </div>
            {/* this is in the middle so that it doesn't fuck up
            the rounded corners which use first of type and last of type */}
            {currentlyActive && (
                <div className={"CurrentlyActiveBanner"}>
                    <Typography variant={"button"}>Now</Typography>
                </div>
            )}
            <div className={"tabElement Spacer"} />
            <div className={"tabElement IdentifierLongName"}>
                <Typography variant={"subheader2"}>Valve {footerText}</Typography>
                <Typography variant={"caption"}>
                    {IrrigationVerb}: <span className={"UpdatedAt"}>{lastUpdatedText}</span>
                </Typography>
            </div>
            <div className={"tabElement IdentifierContainer"}>
                <div className={"IdentifierHeader"}>
                    <Typography variant={"caption"}>{IrrigationVerb}</Typography>
                </div>
                <div className={"IdentifierBody"}>
                    {doubleValveDevice && (
                        <>
                            {[0, 1].map((i) => (
                                <div key={i} className={"IndividualValveState"}>
                                    {/* <div className={"IndividualValveState__label"}> */}
                                    {/*    <Typography variant={"subtitle2"}>V{i + 1}</Typography> */}
                                    {/* </div> */}
                                    <div className={"IndividualValveState__icon"}>
                                        {valvesIntendedOpen[i] && <ValveOpenDroplet valveText={`V${i + 1}`} />}
                                        {!valvesIntendedOpen[i] && <ValveCloseDroplet valveText={`V${i + 1}`} />}
                                    </div>
                                    {/* <div className={"IndividualValveState__state"}> */}
                                    {/*    <Typography variant={"caption"}> */}
                                    {/*        {valvesIntendedOpen[i] ? "open" : "close"} */}
                                    {/*    </Typography> */}
                                    {/* </div> */}
                                </div>
                            ))}
                        </>
                    )}
                    {!doubleValveDevice && (
                        <div className={"IndividualValveState"}>
                            <div className={"IndividualValveState__icon"}>
                                {valvesIntendedOpen[0] && <ValveOpenDroplet />}
                                {!valvesIntendedOpen[0] && <ValveCloseDroplet />}
                            </div>
                            {/* <div className={"IndividualValveState__state"}> */}
                            {/*    <Typography variant={"caption"}>{valvesIntendedOpen[0] ? "open" : "close"}</Typography> */}
                            {/* </div> */}
                        </div>
                    )}
                </div>
                <div className={"IdentifierFooter"}>
                    <Typography variant={"caption"}>{footerText}</Typography>
                </div>
            </div>
            {/* <div className={"DataContainer"}> */}
            {/*    {individualVerification?.dataMeasured.map((dataPoint) => { */}
            {/*        let unit = DataTypeConfig.DataTypeConfigurations[dataPoint.dataType]?.databaseUnit; */}
            {/*        let parsedValue = (Math.round(dataPoint.value * 100) / 100).toString(); */}
            {/*        return ( */}
            {/*            <div */}
            {/*                className={`VerificationDataPoint VerificationDataPoint--${ */}
            {/*                    dataPoint.acceptableValue === false */}
            {/*                        ? "failed" */}
            {/*                        : dataPoint.acceptableValue === true */}
            {/*                        ? "passed" */}
            {/*                        : "unknown" */}
            {/*                }`} */}
            {/*            > */}
            {/*                {parsedValue} {unit} */}
            {/*            </div> */}
            {/*        ); */}
            {/*    })} */}
            {/* </div> */}
        </div>
    );
}
