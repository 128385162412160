import { Stack, SxProps, Typography } from "@mui/material";

interface BetaTagProps {
    sx?: SxProps;
    wrapperSx?: SxProps;
}
export default function BetaTag({ sx, wrapperSx }: BetaTagProps) {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            maxWidth={"fit-content"}
            padding={"0.4rem"}
            borderRadius={2}
            height={"1.6rem"}
            sx={[
                {
                    backgroundColor: "var(--beta)",
                },
                ...(Array.isArray(wrapperSx) ? wrapperSx : [wrapperSx]),
            ]}
        >
            <Typography
                variant={"button"}
                color={"#fff"}
                fontSize={"0.8rem"}
                fontWeight={"700"}
                textAlign={"center"}
                lineHeight={"1.1"}
                sx={[...(Array.isArray(sx) ? sx : [sx])]}
            >
                BETA
            </Typography>
        </Stack>
    );
}
