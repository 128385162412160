import "./IconDatePicker.scss";

import EventIcon from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker } from "@mui/x-date-pickers";
import { AddEndingToDate, ShortDayLookup, ShortMonthLookup } from "gardenspadejs/dist/dateHelpers";
import { GenerateUID, getFirstDefined } from "gardenspadejs/dist/general";
import React from "react";
import { isMobile } from "react-device-detect";

import { DateAvatar } from "../../../modules/scheduleEditor/components/DateAvatar";

export default function IconDatePicker(props) {
    const [pickerOpen, setPickerOpen] = React.useState(false);
    const [, setDummy] = React.useState(0);
    const uid = React.useMemo(() => GenerateUID("IconDatePicker"), []);
    const className = "IconDatePicker";
    const selectedTime = getFirstDefined(props.value, props.defaultValue, new Date(Date.now()));
    return (
        <div className={className} id={uid}>
            <IconButton
                onClick={() => {
                    setPickerOpen(true);
                }}
                size={"small"}
            >
                <DateAvatar date={selectedTime} includeYear={props.includeYear} includeTime={props.includeTime} />
            </IconButton>

            <DatePicker
                renderInput={() => null}
                onClose={() => {
                    setPickerOpen(false);
                }}
                open={pickerOpen}
                PopperProps={
                    isMobile
                        ? null
                        : {
                              anchorEl: () => document.getElementById(uid),
                          }
                }
                disableMaskedInput={true}
                style={{ display: "none" }}
                variant={isMobile ? "dialogue" : "inline"}
                margin={"normal"}
                value={selectedTime}
                labelFunc={(date) =>
                    `${ShortDayLookup[date.getDay()]} ${ShortMonthLookup[date.getMonth()]}. ${AddEndingToDate(
                        date.getDate(),
                    )}`
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={"start"}>
                            <EventIcon color={"action"} />
                        </InputAdornment>
                    ),
                }}
                onChange={(date) => {
                    const newStartTime = new Date(selectedTime.valueOf());
                    newStartTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                    if (props.onChange) {
                        props.onChange(newStartTime);
                    }
                    setDummy(Math.random());
                }}
            />
        </div>
    );
}
