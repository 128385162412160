import { Box, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";

import { DateRangeButtons } from "./DateRangeButtons";

interface DateRangeSelectorProps {
    startDate: Date;
    setStartDate: (newStartDate: Date) => void;
    endDate: Date;
    setEndDate: (newEndDate: Date) => void;
}

export function DateRangeSelector({ startDate, setStartDate, endDate, setEndDate }: DateRangeSelectorProps) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <Box>
            <Typography variant={"h4"}>Date Range</Typography>
            <Typography variant={"subtitle1"}>Select the date range to export data for.</Typography>
            <Typography variant={"subtitle1"}>
                Dates are <strong>inclusive</strong> and in your current timezone.
            </Typography>
            <Typography variant={"subtitle1"}>
                Current timezone: <i>{`${timeZone}`}</i>
            </Typography>

            <Box sx={{ mt: 6 }}>
                <DateRangeButtons
                    onClick={(newStartDate, newEndDate) => {
                        setStartDate(newStartDate);
                        setEndDate(newEndDate);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    sx={{ mb: 6 }}
                />

                <Stack direction={"row"} spacing={4}>
                    <DatePicker
                        label={"Start Date"}
                        renderInput={(params) => <TextField {...params} />}
                        value={startDate}
                        onChange={(newStartDate: Date | null) => {
                            if (newStartDate) {
                                setStartDate(newStartDate);
                            }
                        }}
                        maxDate={endDate}
                    />
                    <DatePicker
                        label={"End Date"}
                        renderInput={(params) => <TextField {...params} />}
                        value={endDate}
                        onChange={(newEndDate: Date | null) => {
                            if (newEndDate) {
                                setEndDate(newEndDate);
                            }
                        }}
                        minDate={startDate}
                    />
                </Stack>
            </Box>
        </Box>
    );
}
