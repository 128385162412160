import { Button, Stack, SxProps, Theme } from "@mui/material";

interface SelectAllButtonGroupProps {
    onClickSelectAll: () => void;
    onClickClear: () => void;
    sx?: SxProps<Theme>;
}

export default function SelectAllButtonGroup({ onClickSelectAll, onClickClear, sx }: SelectAllButtonGroupProps) {
    return (
        <Stack direction={"row"} spacing={2} sx={{ ...sx }}>
            <Button
                size={"small"}
                sx={{ borderRadius: 10, borderColor: "#8d8d8d", color: "#8d8d8d" }}
                color={"inherit"}
                variant={"outlined"}
                onClick={onClickSelectAll}
            >
                Select all
            </Button>

            <Button
                size={"small"}
                sx={{ borderRadius: 10, borderColor: "#8d8d8d", color: "#8d8d8d" }}
                color={"inherit"}
                variant={"outlined"}
                onClick={onClickClear}
            >
                Remove All
            </Button>
        </Stack>
    );
}
