import { Close as CloseIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";
import { CardHeader } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import React from "react";

export const DefaultHeader = React.memo(
    React.forwardRef((props, ref) => {
        let trueAvatar = props.avatar;

        if (!React.isValidElement(trueAvatar)) {
            trueAvatar = <props.avatar mapEntity={props.mapEntity} Target={props.Target} />;
        }

        return (
            <CardHeader
                ref={ref}
                className={"InfoCardHeader"}
                onClick={() => {}}
                title={props.title}
                action={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                        }}
                    >
                        {props.action || null}
                        {props.duckable && (
                            <IconButton
                                aria-label={"open"}
                                onClick={(e) => {
                                    props.headerClickHandler(e);
                                }}
                                size={"large"}
                            >
                                <ExpandLessIcon className={"InfoPanel_HideButton"} />
                            </IconButton>
                        )}
                        {!props.duckable && (
                            <IconButton
                                aria-label={"close"}
                                onClick={(e) => {
                                    props.closeHandler(e);
                                }}
                                size={"large"}
                            >
                                <CloseIcon className={"InfoPanel_CloseButton"} />
                            </IconButton>
                        )}
                    </div>
                }
                subheader={props.subheader || ""}
                avatar={trueAvatar}
            />
        );
    }),
);
export const DefaultAvatar = React.memo((props) => {
    if (!props.Target) {
        return null;
    }
    const mapEntity = props.mapEntity || props.Target.mapEntity;
    if (!mapEntity) {
        return null;
    }
    if (props.Target.category === "zone") {
        return null;
    }
    let avatarClassName = "InfoCardAvatar ME MEo ";
    if (props.Target && props.Target.status) {
        avatarClassName += ` ME_${props.Target.status} `;
    }
    if (mapEntity) {
        avatarClassName += (mapEntity.DOMClassNames || []).join(" ");
    }
    return (
        <Avatar aria-label={"Sensor"} className={avatarClassName} style={{ height: "48px", width: "48px" }}>
            <mapEntity.icon style={{ transform: "scale(1.2)" }} />
        </Avatar>
    );
});
