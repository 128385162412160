import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { languages } from "../../../i18n/constants";
import BetaTag from "../../generic/BetaTag/BetaTag";

// codes should match the locale codes in the i18n/locales folder
const languageOptions = [
    { code: "en-CA", flagCode: "CA", label: "English", beta: false },
    { code: "en-US", flagCode: "US", label: "English", beta: false },
    { code: "es-MX", flagCode: "MX", label: "Español", beta: true },
    { code: "pa-IN", flagCode: "IN", label: "ਪੰਜਾਬੀ", beta: true },
    { code: "fr-CA", flagCode: "CA", label: "Français", beta: true },
    { code: "pt-PT", flagCode: "PT", label: "Português", beta: true },
    { code: "ja-JP", flagCode: "JP", label: "日本語", beta: true },
];

function isLanguageValid(language: string | undefined) {
    return languageOptions.some((option) => option.code === language);
}

export function LanguageSelector() {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState<string | undefined>(languages.default);

    const onChangeLanguageOption = (e: SelectChangeEvent) => {
        const selectedLanguage = e.target.value;

        // Ensure selected language is valid before attempting to set
        if (!isLanguageValid(selectedLanguage)) {
            return;
        }

        i18n.changeLanguage(selectedLanguage);

        localStorage.setItem("languageSetting", selectedLanguage);
        // Update the language state
        setLanguage(selectedLanguage);
    };

    useEffect(() => {
        // Attempt to get the stored language from localStorage
        const storedLanguage = localStorage.getItem("languageSetting") || undefined;

        // Set the stored language if valid otherwise try to use the browser's language if valid
        if (isLanguageValid(storedLanguage)) {
            setLanguage(storedLanguage);
            i18n.changeLanguage(storedLanguage);
        } else {
            const browserLanguage = navigator.language;

            if (isLanguageValid(browserLanguage)) {
                setLanguage(browserLanguage);
                i18n.changeLanguage(browserLanguage);
            }
        }
    }, [i18n]);

    return (
        <Stack justifyContent={"center"}>
            <FormControl size={"small"}>
                <Select
                    size={"small"}
                    open={open}
                    onOpen={() => setOpen(!open)}
                    value={language}
                    onChange={onChangeLanguageOption}
                    onClose={() => setOpen(false)}
                    MenuProps={{
                        sx: {
                            top: 5, // Aligns the dropdown with the input
                        },
                    }}
                    sx={{
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        // Override focus state
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "1px",
                        },
                    }}
                    renderValue={(selected) => {
                        const selectedOption =
                            languageOptions.find((option) => option.code === selected) || languageOptions[0];
                        return <LanguageOptionItem option={selectedOption} hideBetaTag={true} />;
                    }}
                >
                    {languageOptions.map((option) => (
                        <MenuItem key={option.code} value={option.code} sx={{ display: "flex", alignItems: "center" }}>
                            <LanguageOptionItem option={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
}

interface LanguageOptionItemProps {
    option: {
        code: string;
        flagCode: string;
        label: string;
        beta: boolean;
    };
    hideBetaTag?: boolean;
}

function LanguageOptionItem({ option, hideBetaTag = false }: LanguageOptionItemProps): JSX.Element {
    return (
        <Stack direction={"row"} justifyContent={"space-between"} flexGrow={1}>
            <div style={{ marginRight: 8 }}>
                <img
                    loading={"lazy"}
                    height={"14"}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.flagCode}.svg`}
                    style={{
                        marginRight: 6,
                        marginBottom: 2, // Vertically align with label text
                        filter: "brightness(0.95)",
                    }}
                />
                {option.label}
            </div>
            {!hideBetaTag && option.beta && <BetaTag />}
        </Stack>
    );
}
